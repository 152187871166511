.modal-trophy {
  .dfb-Modal.is-active {
    overflow-y: hidden;
  }

  .dfb-Modal-contentContainer {
    padding: 0px;
    background-color: transparent;

    .dfb-Symbol--dfbIconClose {
      background-color: $dfb-color-black;
    }
  }

  .dfb-Modal-modalHeader {
    margin-bottom: 0;
  }

  &__container {
    position: relative;
  }

  &__header {
    background-color: $dfb-color-black;
    margin-bottom: 0;
    color: $dfb-color-white;
    position: absolute;
    top: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 20px;

    @include dfb-respond-to(m) {
      padding: 0 3rem;
    }

    .modal__header--logo {
      width: 50%;
    }
  }

  &__footer {
    position: absolute;
    background-color: transparent;

    .social-media {
      &__wrapper {
        position: absolute;
        max-width: 285px;
        left: 4%;
        bottom: 120px;
        @include dfb-respond-to (s) {
          left: 29%;
          bottom: 150px;
          width: 318px;
          max-width: 100%;
        }
        @include dfb-respond-to (m) {
          left: 35%;
        }
      }
    }

    .btn-share {
      position: absolute;
      width: 159px;
      height: 40px;
      left: 39%;
      bottom: 90px;
      margin: auto;
      @include dfb-respond-to (s) {
        left: 62%;
        bottom: 120px;
      }
    }
  }
}

.trophy {
  &__title {
    font-weight: 700;
    margin-bottom: $dfb-spacing-m;
  }

  &__content {
    position: absolute;
    top: 64px;
    color: #fff;
    padding: 4.5rem 20px;

    @include dfb-respond-to(m) {
      top: 140px;
      padding: 0 3rem;
    }

    .trophy__text {
      &:not(:first-of-type) {
        padding-top: 0.7rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100vh;

    @include dfb-respond-to(m) {
      height: auto;
    }
  }
}
