.social-media {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: white;

  &__wrapper {
    display: flex;
    justify-content: space-around;
    visibility: hidden;
    width: 318px;
    height: 61px;
    background-color: white;
    color: #fff;
    box-shadow: $dfb-card-box-shadow;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 60px;
    left: -107%;

    &--show {
      visibility: visible;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
    }

    &-facebook {
      background-color: #3b5998;
      @include social-media-sharing-buttons;
    }

    &-twitter {
      background-color: #00aced;
      @include social-media-sharing-buttons;
    }

    &-whatsapp {
      background-color: #53ab83;
      @include social-media-sharing-buttons;
    }

    &-email {
      background-color: $dfb-color-grey5;
      @include social-media-sharing-buttons;
    }

    &-print {
      cursor: pointer;
      background-color: $dfb-color-grey5;
      @include social-media-sharing-buttons;
    }
  }
}

.btn-share {
  z-index: 2;
  position: relative;
  left: 5%;

  svg {
    fill: $dfb-color-white;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}


