.section-teamplayer {
  overflow: hidden;

  &__content {
    position: relative;
    width: 100%;
    margin-top: 3.5rem;
    @include dfb-respond-to(l) {
      height: 450px;
      margin-top: 8.5rem;
    }
  }

  &__image {
    position: absolute;
    z-index: -1;
    left: -385px;
    bottom: -172px;
    scale: 70%;

    @include dfb-respond-to(s) {
        left: -370px;
    }
    @include dfb-respond-to(m) {
      left: -214px;
      bottom: -4px;
      scale: 100%;
    }
  }

  &__logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    margin-left: 22px;
    margin-bottom: 85px;

    @include dfb-respond-to (s) {
        margin-left: 50px;
        margin-bottom: 54px;
    }

    @include dfb-respond-to (m) {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 102px;

        @include dfb-grid-cell(10, map-get($dfb-grid-columns, m));
        margin-left: 127px;
    }
    
    &--bmwki {
        width: 155px;
        height: auto;

        @include dfb-respond-to (s) {
            width: 235px;
            height: auto;
        }

        @include dfb-respond-to (m) {
            width: 275px;
            height: auto;
        }
    }
    &--uefa {
        width: 150px;
        height: auto;

        @include dfb-respond-to (s) {
            width: 190px;
            height: auto;
        }

        @include dfb-respond-to (m) {
            width: 205px;
            height: auto;
        }
    }
    &--dosb {
        width: 95px;
        height: auto;

        @include dfb-respond-to (s) {
            width: 130;
            height: auto;
        }

        @include dfb-respond-to (m) {
            width: 150px;
            height: auto;
        }
    }

    &--outer-flex {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    &--inner-flex {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-left: -10px;
        margin-top: -10px;
        @include dfb-respond-to (m) {
            margin-top: -20px;
        }
    }
  }
}

