.germany-map {
  font-family: sans-serif;

  &__col {
    @include dfb-respond-to(s) {
      max-width: 50%;
    }
    @include dfb-respond-to(m) {
      max-width: inherit;
    }
  }

  &__image {
    position: absolute;
    left: -12%;
    margin-top: -20%;
    opacity: 50%;
    width: 70%;
    height: auto;
    max-width: 280px;

    @include dfb-respond-to(s) {
      position: relative;
      width: 80%;
      margin-top: -20%;
      left: inherit;
      opacity: inherit;
    }
    @include dfb-respond-to(m) {
      width: 100%;
      margin-top: -80%;
      left: -12%;
      max-width: 100%;
    }
    @include dfb-respond-to(l) {
      margin-top: -70%;
    }

    path {
      fill: $dfb-color-germany-map-lightgreen;
      stroke: $dfb-color-white;
      stroke-width: 1.56px;

      @include dfb-respond-to(s) {
        &.active {
          fill: $dfb-color-germany-map-lightgreen--active;
        }
      }
    }
  }

  &__dropdown-container {
    display: flex;
    flex-direction: column;

    @include dfb-respond-to(l) {
      flex-direction: row;
    }

    .dfb-Dropdown {
      &-label {
        color: $dfb-color-webblack;
      }

      &-value {
        color: $dfb-color-grey5;
      }

      &-toggle {
        fill: $dfb-color-grey5;
      }

      &-listbox {
        text-align: left;

        ul > div:not(:last-of-type) {
          .dfb-Dropdown-item {
            border-bottom: 2px solid #bababa;
          }
        }
      }
    }
  }

  &__dropdown {
    background-color: $dfb-color-white;
    margin-right: $dfb-spacing-rem-l;

    &--first {
      width: inherit;
      margin-bottom: 3.375rem;

      @include dfb-respond-to(l) {
        width: 19rem;
      }
    }

    &--second {
      width: inherit;

    }
  }

  &__cta {
    align-self: flex-end;
    margin-top: 2.5rem;
    @include dfb-respond-to(l) {
      margin-top: 0.5rem;
      align-self: inherit;
      margin-left: $dfb-spacing-rem-l;
    }
  }
}
