@use 'sass:math';

.section {
  height: auto;
  margin-top: $dfb-spacing-xxl + $dfb-spacing-s;
  overflow-x: clip;

  @include dfb-respond-to(m) {
    margin-top: $dfb-spacing-xxxl + $dfb-spacing-l;
  }

  &--full {
    height: 100%;
    min-height: 100vh;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.col {
  flex: 1 0 0%;
}

.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.margin-lr-auto {
  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 100%;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

// DFB Container
.grid {
  &-container {
    @include dfb-grid-container;
  }

  &-wrapper {
    @include dfb-grid-wrapper;
  }

  &-offset-pre {
    &--one {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(1);
      }
    }

    &--two {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(2);
      }
    }

    &--three {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(3);
      }
    }

    &--four {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(4);
      }
    }

    &--five {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(5);
      }
    }

    &--six {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(6);
      }
    }
  }

  &-offset-post {
    &--one {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(1);
      }
    }

    &--two {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(2);
      }
    }

    &--three {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(3);
      }
    }

    &--four {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(4);
      }
    }

    &--five {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(5);
      }
    }

    &--six {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(6);
      }
    }

  }

  &-cell--one {
    @include dfb-grid-cell(1, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(1, map-get($dfb-grid-columns, l));
    }
  }

  &-cell--three {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, l));
    }
    @include dfb-respond-to(xl) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, xl));
    }
  }

  &-cell--four {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(4, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--five {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--half {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--seven {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(7, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--eight {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(4, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(8, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--ten {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(10, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--twelve {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(12, map-get($dfb-grid-columns, m));
    }
    min-height: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.col {
  flex: 1 0 0%;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.margin-lr-auto {
  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 100%;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

// DFB Container
.grid {
  &-container {
    @include dfb-grid-container;
  }

  &-wrapper {
    @include dfb-grid-wrapper;
  }

  &-cell--one {
    $-columns: map-get($dfb-grid-columns, xs);
    @include dfb-grid-cell(3, $-columns);
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(1, map-get($dfb-grid-columns, l));
    }
  }

  &-cell--three {
    @include dfb-grid-cell(3, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, l));
    }
    @include dfb-respond-to(xl) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, xl));
    }
  }

  &-cell--four {
    @include dfb-grid-cell(2, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(4, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--half {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--seven {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(7, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--ten {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(10, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--twelve {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(12, map-get($dfb-grid-columns, m));
    }
  }
}

/************************************************
 * DFB Spacing
 ************************************************/
//  $dfb-spacing-baseUnit: 16px !default;
//  $dfb-spacing-xxxs: 0.25 * $dfb-spacing-baseUnit; // 4px
//  $dfb-spacing-xxs: 0.5 * $dfb-spacing-baseUnit; // 8px
//  $dfb-spacing-xs: 0.75 * $dfb-spacing-baseUnit; // 12px
//  $dfb-spacing-s: 1 * $dfb-spacing-baseUnit; // 16px
//  $dfb-spacing-m: 1.5 * $dfb-spacing-baseUnit; // 24px
//  $dfb-spacing-l: 2 * $dfb-spacing-baseUnit; // 32px
//  $dfb-spacing-xl: 3 * $dfb-spacing-baseUnit; // 48px
//  $dfb-spacing-xxl: 4 * $dfb-spacing-baseUnit; // 64px
//  $dfb-spacing-xxxl: 6 * $dfb-spacing-baseUnit; // 96px
//  $dfb-spacing-xxxxl: 10 * $dfb-spacing-baseUnit; // 160px

//  $dfb-spacing-rem-xxxs: 0.25rem; // 4px
//  $dfb-spacing-rem-xxs: 0.5rem; // 8px
//  $dfb-spacing-rem-xs: 0.75rem; // 12px
//  $dfb-spacing-rem-s: 1rem; // 16px
//  $dfb-spacing-rem-m: 1.5rem; // 24px
//  $dfb-spacing-rem-l: 2rem; // 32px
//  $dfb-spacing-rem-xl: 3rem; // 48px
//  $dfb-spacing-rem-xxl: 4rem; // 64px
//  $dfb-spacing-rem-xxxl: 6rem; // 96px
//  $dfb-spacing-rem-xxxxl: 10rem; // 160px
