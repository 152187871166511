.modal {
  &__header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    &--logo {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;

      img{
        width: 48px;
        height: 48px;
        margin-right: $dfb-spacing-m;
      }
    }
    &--keyvisual {
      width: 115px;
      height: 47px;
      background-color: $dfb-color-grey3;
      text-align: center;
      text-transform: capitalize;
    }
  }
  &__bubble {
    background: url("../../images/dfb-share.svg") no-repeat center center;
    height: 400px;
    width: 430px;
  }
}


