.card-slider {
    width: 100%;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 1rem 0rem 3rem 0rem;

    @include dfb-respond-to(s) {
        width: 430px;
        padding: 1rem 1.5rem 3rem 1.5rem;
    }
}

.swiper .swiper-wrapper {
    box-sizing: border-box;
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination .swiper-pagination-bullet {
    background: $dfb-color-success;
    height: 6px;
    width: 6px;
    opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: $dfb-color-success;
    height: 12px;
    width: 12px;
}