@mixin reset-dropdown-font-size {
  &-label {
    font-size: inherit;
  }
  &-value {
    font-size: inherit;
  }
  &-toggle {
    width: 16px;
    right: 16px;
  }
}

@mixin social-media-sharing-buttons {
  padding: 5px;
  width: 40px;
  height: 40px;
}




