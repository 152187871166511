.hero {
  &__container {
    background: url('../../images/image-stage-XS.png') no-repeat center center;
    height: calc(70vh - 70px);
    width: 100%;
    position: relative;
    background-size: cover;
    padding: $dfb-spacing-xxs;
    margin: 0;
    overflow: hidden;

    @include dfb-respond-to(m) {
      background: url('../../images/image-stage-XL.png') no-repeat center center;
      background-size: cover;
      height: calc(100vh - 70px);
    }

    @include dfb-respond-to(l) {
      padding: $dfb-spacing-xxl;
    }
  }

  &__blob-image {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-47%, -30%) rotate(116deg);
    opacity: 0.35;
    z-index: 0;

    svg {
      width: 750px;
      height: 600px;

      @include dfb-respond-to(m) {
        width: auto;
        height: auto;
      }
    }
  }

  &__text {
    color: $dfb-color-white;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 3;

    &--small {
      line-height: 34px;
      letter-spacing: 4px;
      margin-bottom: $dfb-spacing-s;
      padding-top: $dfb-spacing-xxl;
      text-decoration-line: underline;
      text-underline-offset: 8px;
      width: 280px;

      @include dfb-respond-to(l) {
        font-size: 24px;
      }
    }

    &--main {
      word-wrap: break-word;
      hyphens: auto;
      max-width: 380px;

      @include dfb-respond-to(s) {
        max-width: 450px;
      }
      @include dfb-respond-to(m) {
        max-width: 550px;
      }
      @include dfb-respond-to(l) {
        max-width: 650px;
      }
    }
  }

  // Button Style
  &__btn {
    display: flex;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

.dfb-button--icon.btn__white {
  width: 75px;
  height: 75px;
  border-color: white;

  span {
    width: 75px;
    height: 38px;

    svg {
      font-size: 40px;
      color: $dfb-color-white;
    }
  }
}
