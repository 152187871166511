.card-logo {
    border-radius: 50%;
    box-shadow: 0px 0px 17px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 162px;
    height: 162px;

    @include dfb-respond-to (s) {
        height: 242px;
        width: 242px;
    }

    @include dfb-respond-to (m) {
        margin-bottom: 105px;
    }

    @include dfb-respond-to (l) {
        height: 282px;
        width: 282px;
    }
}
