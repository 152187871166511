.video-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.25);

  &__wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
  }

  &__cc {
    text-align: right;
    margin-top: $dfb-spacing-rem-s;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
