.modal {
  position: relative;
  overflow: hidden;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 40px;

    @include dfb-respond-to(m) {
      flex-direction: row;
    }

    @include dfb-respond-to(l) {
      gap: 3%;
    }
  }

  &__text-padding:not(:last-of-type) {
    padding-bottom: 22px;
  }

  &__left {
    @include dfb-respond-to(m) {
      width: 50%;
      order: 1;
    }
  }

  &__title {
    width: 50%;
    padding-bottom: 30px;

    @include dfb-respond-to(m) {
      width: 100%;
      font-size: 28px;
    }
    @include dfb-respond-to(l) {
      font-size: 32px;
    }
  }

  &__subtitle {
    display: flex;
    gap: 8px;
  }

  &__lesezeit {
    color: $dfb-color-grey4;
    margin-bottom: 20px;
  }

  &__right {
    // margin-top: 30px;
    @include dfb-respond-to(m) {
      width: 50%;
      order: 3;
    }

    &--img-slider {
      @include dfb-respond-to(m) {
        margin-left: -466px;
        margin-top: 420px;
      }
      @include dfb-respond-to(l) {
        margin-left: -460px;
      }
    }
    .btn-bg-green p {
      margin-top: 10px;
    }
  }

  &__images {
    @include dfb-respond-to(m) {
      order: 2;
      margin-bottom: 44px;
      width: 50%;
    }
    .card-slider {
      min-width: 100%;
    }

    img {
      width: 100%;
      height: 330px;
    }
  }

  &__link {
    margin: 40px 0;

    &-subtitle {
      letter-spacing: 1.5px;
      text-transform: uppercase;

      color: #212121;
    }

    ul {
      margin: 16px 0;
    }

    .info__link.paragraph-big {
      padding: 6px 0;
    }
  }

  &__merkmale {
    &--margin {
      margin-top: 22px;
    }

    &-title {
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  &__footer {
    height: 40px;
    .blob-image {
      position: absolute;
      bottom: -274px;
      left: 0;
      transform: translate(8%, -8%) rotate(-37deg);
      opacity: 0.2;
      z-index: 0;
      pointer-events: none;
      @include dfb-respond-to(s) {
        transform: translate(31%, -8%) rotate(-37deg);
      }
      @include dfb-respond-to(m) {
        transform: translate(10%, 7%) rotate(-37deg);
      }
      @include dfb-respond-to(l) {
        transform: translate(32%, 14%) rotate(-30deg);
      }
    }
  }

  .color-green {
    color: $dfb-color-green-primary;
  }
  .card-slider {
    padding: 0;
    max-width: 100%;
    margin-top: 0px;
  }
  .swiper-wrapper {
    height: 375px;
  }
  &__no-img-slider {
    display: none;
  }
  .f-bold {
    font-weight: 700;
  }
  &__massnahmen--social-btn {
    margin-top: 40px;
    transform: translateX(49%);

    @include dfb-respond-to(s) {
      margin-top: 60px;
      transform: translateX(77%);
    }
    @include dfb-respond-to(m) {
      transform: translateX(60%);
    }
  }
}

.dfb-Modal-contentContainer {
  overflow: hidden;
}

.section-massnahmen .modal__container .btn-bg-green a {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.75px;
  background-color: $dfb-color-success;
  border: 2px solid $dfb-color-success;
  color: $dfb-color-white;
  height: 40px;
  padding: 0 30px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 40px;
  -webkit-transition-timing-function: cubic-bezier(0.16, 0, 0.12, 1);
  transition-timing-function: cubic-bezier(0.16, 0, 0.12, 1);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;

  .info__link--icon {
    fill: $dfb-color-white;
  }
}
