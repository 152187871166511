.section-taktik {
  position: relative;
  overflow: visible;

  &__background {
    position: absolute;
    left: 0;
    width: calc(100% - 14px);
    top: 32%;
    bottom: 0;

    @include dfb-respond-to(s) {
      top: 25%;
      max-width: 670px;
      max-height: auto;
    }
    @include dfb-respond-to(m) {
      top: 25%;
      margin-top: 5rem;
      max-width: 100%;
      max-height: 100%;
      width: 90%;
    }

    @include dfb-respond-to(l) {
      margin-top: 0;
    }
  }

  &__card {
    max-width: 350px;

    &--first {
      margin-top: 4rem;
      @include dfb-respond-to(xl) {
        margin-top: 6rem;
      }
    }

    &--second {
      margin-right: 0;
      margin-top: 9rem;

      @include dfb-respond-to(m) {
        margin-left: -3rem;
      }

      @include dfb-respond-to(l) {
        margin-left: 3.5rem;
      }

      @include dfb-respond-to(xl) {
        margin-top: 18rem;
        margin-left: 5rem;
      }
    }

    &--third {
      margin-left: -2rem;
      margin-top: 3rem;

      @include dfb-respond-to(l) {
        margin-top: 8rem;
        margin-left: -3rem;
      }

      @include dfb-respond-to(xl) {
        margin-top: 13rem;
        margin-left: -4rem;
      }
    }

    &--fourth {
      margin-left: -4rem;
      margin-top: 6rem;

      @include dfb-respond-to(l) {
        margin-top: 13rem;
      }

      @include dfb-respond-to(xl) {
        margin-top: 22rem;
      }
    }

    &--slider {
      padding: 1rem 1.5rem 3rem 1.5rem;
    }
  }

  &__video {
    width: 100%;
    padding: $dfb-spacing-s;

    @include dfb-respond-to(s) {
      padding: $dfb-spacing-xxl $dfb-spacing-s $dfb-spacing-xl $dfb-spacing-s;
    }
    @include dfb-respond-to(m) {
      padding: $dfb-spacing-xxxl $dfb-spacing-s $dfb-spacing-s $dfb-spacing-s;
    }
  }

  .dfb-Modal-contentContainer {
    max-width: 1000px;
    padding: 4.5rem 1.25rem;
    @include dfb-respond-to(m) {
      padding: 4.5rem 3rem;
    }
  }
}
