.section-vereinskasse {
  .section-head {
    &__subtitle {
      width: 100%;
      @include dfb-respond-to(m) {
        width: inherit;
      }
    }
  }

  .massnahmen__status {
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    @include dfb-respond-to(m) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }

    span {
      font-size: 18px;

      &:last-of-type {
        color: inherit;
        font-weight: inherit;
      }
    }
  }

  &__map {
    margin-top: $dfb-spacing-xxxl;
  }

  &__results-headline {
    margin-top: 3.5rem;
  }
}
