.blob {
    // width: 100%;
    // height: 100%;
    mask-image: url("../../images/blob-team-players.svg");
    mask-repeat: no-repeat;
    transform: translate(-9%);

    &__image {
        position: relative;
        left: -150px;
        width: 1090px;
    }
}