.header {
  &__wrapper {
    height: 70px;
    display: flex;
    align-items: center;

    .scrolling-up & {
      position: sticky;
      top: 0;
      background-color: $dfb-color-white;
      z-index: 200;

      @include dfb-respond-to(m) {
        position: relative;
      }
    }

    //.scrolling-down {
    //  top: -70px;
    //  transition: top 0.2s ease-out;
    //}
  }

  &__nav {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 200;
  }

  &__img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  &__logo {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    color: $dfb-color-webblack;
    display: flex;
    width: 240px;
    justify-content: flex-start;
    align-items: center;

    @include dfb-respond-to(s) {
      font-size: 16px;
      line-height: 100%;
      width: 440px;
    }
  }

  &__brand {
    width: 115px;
    height: 48px;
    object-fit: contain;
    display: none;

    @include dfb-respond-to(l) {
      display: block;
      text-align: center;
      position: absolute;
      right: 85px;
    }
  }
}

.nav {
  &__menu {
    position: fixed;
    z-index: 1;
    display: grid;
    grid-template-rows: 12% 12% 12% 12% 12%;
    background-color: #53ab83; //
    top: 70px;
    padding-top: 60px;
    width: 100%;
    height: calc(100vh - 70px);
    right: 0;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }

  &__collapse {
    transform: translateX(0%);
  }

  &__item {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $dfb-color-white;
    margin: 24px;
    display: flex;
    align-items: center;

    @include dfb-respond-to(l) {
      font-size: 56px;
      font-weight: 700;
      position: relative;
      line-height: 62px;
      grid-column-start: 4;
    }
  }

  &__img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    filter: invert(100%);
    cursor: pointer;
  }

  &__link {
    cursor: pointer;
  }

  // background image with clover
  &__bubble-img {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -40%) rotate(165deg);
    z-index: -1;
    filter: invert(64%) sepia(54%) saturate(175%) hue-rotate(114deg) brightness(131%) contrast(93%);

    svg {
      width: 600px;
      height: 600px;

      @include dfb-respond-to(l) {
        width: auto;
        height: auto;
      }
    }

    @include dfb-respond-to(l) {
      transform: translate(-40%, -49%) rotate(165deg);
    }
  }

  &__logo-box {
    width: 278px;
    max-width: 478px;
    max-height: 250px;
    color: white;
    margin: 24px;
    @include dfb-respond-to(l) {
      grid-column-start: 4;
      width: 347px;
    }
  }
}

a {
  color: $dfb-color-white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
