.section-card {
  position: relative;
  border-bottom: 5px solid #53ab83;
  cursor: pointer;

  &__link {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    position: absolute;
    z-index: 1;
  }

  .dfb-Anchor {
    z-index: 2;
  }

  &__teaser {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding-bottom: 0.5rem;
  }

  &__titel {
    font-size: 18px;
    line-height: 24px;
    color: #53ab83;
    padding-bottom: 1rem;
  }

  &__subtitel {
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    padding-bottom: 1rem;
  }

  &__description {
    line-height: 24px;
    padding-bottom: 1rem;
  }
}
