h1,
.h1 {
  @include dfb-typography-h1();
}

h2,
.h2 {
  @include dfb-typography-h2();
}

h3,
.h3 {
  @include dfb-typography-h3();
}

h4,
.h4 {
  @include dfb-typography-h4();
}

h5,
.h5 {
  @include dfb-typography-h5();
}

h6,
.h6 {
  @include dfb-typography-h6();
}

p {
  @include dfb-typography-paragraph();
}

.paragraph-big {
  @include dfb-typography-paragraph-big();
}

.paragraph-small {
  @include dfb-typography-paragraph-small();
}

.typo-btn {
  @include dfb-typography-button();
}

a {
  text-decoration: none;
}
