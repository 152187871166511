.burger-button {
  .dfb-Icon svg {
    overflow: initial;
  }

  & .dfb-IconButton {
    color: $dfb-color-grey5;
  }

  &--is-active {
    .dfb-IconButton {
      color: $dfb-color-grey5;
    }
    #burger-button-top {
      transform: translateY(7px) rotate(225deg);
    }
    #burger-button-center {
      transform: rotate(135deg);
    }
    #burger-button-bottom {
      opacity: 0;
      transform: translateY(-7px) translateX(3px) rotate(135deg);
    }
  }

  & #burger-button-top,
  & #burger-button-center,
  & #burger-button-bottom {
    transform-box: fill-box;
    transform-origin: center;
    transition-duration: 0.4s;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.16, 0, 0.12, 1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  & #burger-button-bottom {
    transition-duration: 0.2s;
  }
}
