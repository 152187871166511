$dfb-card-box-shadow: 0 4px 24px rgba(0, 0, 0, 0.19);
$dfb-card-box-shadow-hover: 0 4px 24px rgba(0, 0, 0, 0.38);

.section {
  &-taktik, &-massnahmen, &-vereinskasse {
    .dfb-Card {
      box-shadow: $dfb-card-box-shadow;
      &:hover:not([disabled]):not([aria-disabled]) {
          box-shadow: $dfb-card-box-shadow-hover;
      }
      &--no-link:hover:not([disabled]):not([aria-disabled]),
      &--no-link:active:not([disabled]):not([aria-disabled]) {
        cursor: default;
        box-shadow: $dfb-card-box-shadow;
        transform: translateY(0);
      }
    }
  }
}



