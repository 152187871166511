.download {
  &__container {
    margin-bottom: $dfb-spacing-xxs;

    a {
      color: $dfb-color-dachmarke-lightGreen;
      font-size: 18px;
      display: flex;
      align-items: center;
      padding-top: $dfb-spacing-xs;
      padding-bottom: $dfb-spacing-xs;
      gap: 5px;

      svg {
        fill: $dfb-color-dachmarke-lightGreen;
      }
    }
    a:hover svg{
      transform:translateY(-5px);
      transition: ease-in 0.2s;
    }
  }
}